/**
 * @ComponentFor FaqContainerBlockViewModel
 */
import React from 'react';
import FaqContainerBlockViewModel from './FaqContainerBlockViewModel.type';
import QuestionsBlockViewModel from './QuestionsBlock.type';
import Html from 'Shared/Partials/PropertyTypes/Html';
import QuestionsBlock from './QuestionsBlock';
import { styled } from '@glitz/react';
import { beta, minMediumMediaQuery, pixelsToUnit } from 'Shared/Style';

type PropType = FaqContainerBlockViewModel & QuestionsBlockViewModel;

const FaqContainerBlock = (props: PropType) => (
  <Container style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
    {!!props.faqJsonLd && (
      <script
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(props.faqJsonLd),
        }}
        type="application/ld+json"
      />
    )}
    <ContainerHeader>{props.header}</ContainerHeader>

    {props.text && <Html html={props.text.html} />}
    <QuestionsContainer>
      <QuestionsBlock questions={props.faq} isMobile={true} />
    </QuestionsContainer>
  </Container>
);

export default FaqContainerBlock;

const Container = styled.div({
  margin: {
    x: 0,
    y: pixelsToUnit(20),
  },
});

const ContainerHeader = styled.div({
  fontWeight: 'bold',
  fontSize: beta,
});

const QuestionsContainer = styled.div({
  width: '100%',
  [minMediumMediaQuery]: {
    width: '60%',
  },
});
